import React from "react"
import Layout from "../../components/common/Layout"
import Footer from "../../components/common/Footer"
import CareersLink from "../../components/common/CareersLink"
import Learn from "../../components/common/Learn"
import ArrowZ from "../../components/common/ArrowZ"
import Cursor from "../../components/common/Cursor"
import ArrowYDown from "../../components/common/ArrowYDown"
import { StaticImage } from "gatsby-plugin-image"
import Kv from "../../components/section/openness/Kv"
import Metrics from "../../components/section/openness/Metrics"
import Source from "../../components/section/openness/Source"

export default function Openness() {
  let title = "OPENNESS"
  let description = "私たちのアプローチ、そのすべてを公開しています。"
  let headTtl = "OPENNESS"
  let blockTtl = "私たちのアプローチ、<br />そのすべてを公開しています。"
  let blockTxt =
    '私たちCRISPは外食の未来をもっと面白く変えていきたい。<br class="u-none__sp" />だからこそ、これまでに培ってきたノウハウもデータも、ビジネスメトリクスまでも、私たちの軌跡を余すところなくオープンにし、飲食業界を盛り上げていくすべての人に共有します。ビジネスのあり方も再定義しながら、外食を進化させていく。<br class="u-none__sp" />共に、日本の外食をひっくり返しましょう。'

  return (
    <Layout title={title} description={description}>
      <div id="page">
        <div id="openness" className="js-page">
          <main id="main">
            <div id="contents" className="contents">
              <Cursor />
              <Kv headTtl={headTtl} blockTtl={blockTtl} blockTxt={blockTxt} />
              <Metrics />
              <Source />
              <Learn backgroundColor={"gradation"} />
              <CareersLink />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}
