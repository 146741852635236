import React from "react"

const Learn = ({ location, title, children, backgroundColor = "default" }) => {
  let learnCommon = [
    {
      link: "https://note.com/crispsaladworks/n/n9bf72d8fb517",
      src: "/asset/img/common/learn/footer_thumb_09.jpg",
      "src@2x": "/asset/img/common/learn/footer_thumb_09@2x.jpg",
      alt: "ICC最高峰のピッチイベント「カタパルト・グランプリ」初出場&入賞",
      ttl: "ICC最高峰のピッチイベント「カタパルト・グランプリ」初出場&入賞",
    },
    {
      link: "https://note.com/hiroshimiyano/n/n79182b1d5a58",
      src: "/asset/img/common/learn/footer_thumb_07.jpg",
      "src@2x": "/asset/img/common/learn/footer_thumb_07@2x.jpg",
      alt: "資金調達のお知らせと、CRISPが目指すコネクティッド・レストランについて",
      ttl: "資金調達のお知らせと、CRISPが目指すコネクティッド・レストランについて",
    },
    {
      link: "https://note.com/crispsaladworks/n/n4356c7a12280",
      src: "/asset/img/common/learn/footer_thumb_06.jpg",
      "src@2x": "/asset/img/common/learn/footer_thumb_06@2x.jpg",
      alt: "メルカリのエンジニアマネージャーがCRISPに入社した理由",
      ttl: "メルカリのエンジニアマネージャーがCRISPに入社した理由",
    },
    {
      link: "https://note.com/crispsaladworks/n/n60c00b49ffd3",
      src: "/asset/img/common/learn/footer_thumb_05.jpg",
      "src@2x": "/asset/img/common/learn/footer_thumb_05@2x.jpg",
      alt: "2020年のCRISP",
      ttl: "2020年のCRISP",
    },
    {
      link: "https://note.com/crispsaladworks/n/n8f5f417f3caa",
      src: "/asset/img/common/learn/footer_thumb_02.jpg",
      "src@2x": "/asset/img/common/learn/footer_thumb_02@2x.jpg",
      alt: "KIOSK【キャッシュレス決済セルフレジの使い方伝授】",
      ttl: "KIOSK【キャッシュレス決済セルフレジの使い方伝授】",
    },
    {
      link: "https://note.com/hiroshimiyano/n/nc0950ef40ef4",
      src: "/asset/img/common/learn/footer_thumb_08.jpg",
      "src@2x": "/asset/img/common/learn/footer_thumb_08@2x.jpg",
      alt: "資金調達のお知らせと、CRISPが実現したい未来について。",
      ttl: "資金調達のお知らせと、CRISPが実現したい未来について。",
    },
  ]

  return (
    <section className={`p-common-learn __${backgroundColor}`}>
      <div className="p-common-learn__wrapper">
        <div className="p-common-learn__header">
          <h2 className="c-ttl__en p-common-learn__header-ttl">
            LEARN ABOUT CRISP
          </h2>
        </div>
        <div className="swiper-container p-common-learn__body">
          <ul className="swiper-wrapper p-common-learn__body-list">
            {learnCommon.map(data => {
              return (
                <li
                  className="swiper-slide p-common-learn__body-item"
                  key={data.link}
                >
                  <a
                    className="js-hover p-common-learn__body-item-link"
                    data-hover-in-time="600"
                    href={data.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="js-scroll__dark c-link__img p-common-learn__body-item-img">
                      <img
                        className="c-img__def p-common-learn__body-item-img-item"
                        src={`${data.src}`}
                        srcSet={`${data.src} 1x, ${data["src@2x"]} 2x`}
                        alt={data.alt}
                        loading="lazy"
                      />
                    </div>
                    <div className="p-common-learn__body-item-content">
                      <h3 className="p-common-learn__body-item-content-ttl">
                        {data.ttl}
                      </h3>
                    </div>
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Learn
