import React from "react"
import ArrowZ from "../../common/ArrowZ"
import Img from "../../common/Img"

const Source = () => {
  return (
    <section className="p-openness-common p-openness-source">
      <div className="p-openness-common__wrapper">
        <a
          className="js-hover p-openness-common__link"
          data-hover-in-time="600"
          href="https://note.com/crispsaladworks/m/m828e98e036a8"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="c-link__img p-openness-common__img">
            <Img
              src="/asset/img/openness/source.jpg"
              src2x="/asset/img/openness/source@2x.jpg"
              spSrc="/asset/img/openness/sp/source.jpg"
              srcWebp="/asset/img/openness/source.jpg.webp"
              srcWebp2x="/asset/img/openness/source@2x.jpg.webp"
              alt="OPEN SOURCE"
              imgClassName="p-openness-common__img-item"
            />
          </div>
          <div className="p-openness-common__content">
            <h2 className="c-ttl__en p-openness-common__content-ttl">
              OPEN SOURCE
            </h2>
            <p className="p-openness-common__content-txt">
              ご自宅で好きなだけCRISPの味を楽しみたい熱狂的なファンの方にも、CRISPのあの味がたまに恋しくなってしまう方にも。もっと気軽にCRISP体験を広げていきたいという思いから、OPEN
              SOURCEとして CRISP SALAD WORKS
              のソース全8種のレシピを公開しています。
            </p>
            <ArrowZ />
          </div>
        </a>
      </div>
    </section>
  )
}

export default Source
