import React from "react"

const ArrowYDown = () => {
  return (
    <span className="c-icon__arrow">
    <span className="c-icon__arrow__origin c-icon__arrow__origin__y__down">
        <svg className="c-icon__arrow__def"
             width="15"
             height="16"
             viewBox="0 0 15 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path className="c-icon__arrow-path"
                  d="M7.0459 1L7.0459 15"
                  stroke="#1F140F"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
            <path className="c-icon__arrow-path"
                  d="M0.99983 8.95386L7.04594 15L13.0938 8.95386"
                  stroke="#1F140F"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    </span>
    <span className="c-icon__arrow__dammy c-icon__arrow__dammy__y__down">
        <svg className="c-icon__arrow__def"
             width="15"
             height="16"
             viewBox="0 0 15 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path className="c-icon__arrow-path"
                  d="M7.0459 1L7.0459 15"
                  stroke="#1F140F"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
            <path className="c-icon__arrow-path"
                  d="M0.99983 8.95386L7.04594 15L13.0938 8.95386"
                  stroke="#1F140F"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    </span>
</span>
  )
}

export default ArrowYDown