import React from "react"
import ArrowZ from "../../common/ArrowZ"
import Img from "../../common/Img"

const Metrics = () => {
  return (
    <section className="p-openness-common p-openness-metrics">
      <div className="p-openness-common__wrapper">
        <a
          className="js-hover p-openness-common__link"
          data-hover-in-time="600"
          href="https://metrics.crisp.co.jp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="js-scroll__dark c-link__img p-openness-common__img">
            <Img
              src="/asset/img/openness/metrics.jpg"
              src2x="/asset/img/openness/metrics@2x.jpg"
              spSrc="/asset/img/openness/sp/metrics.jpg"
              srcWebp="/asset/img/openness/metrics.jpg.webp"
              srcWebp2x="/asset/img/openness/metrics@2x.jpg.webp"
              alt="CRISP METRICS"
              imgClassName="p-openness-common__img-item"
            />
          </div>
          <div className="p-openness-common__content">
            <h2 className="c-ttl__en p-openness-common__content-ttl">
              CRISP METRICS
            </h2>
            <p className="p-openness-common__content-txt">
              CRISPでは、売上、客数、LTVやアプリユーザーの離脱率、顧客満足度など、私たちが指標にしているKPIをすべてリアルタイムで公開。データドリブンな経営をオープンにすることで、新しい外食の形をつくります。
            </p>
            <ArrowZ />
          </div>
        </a>
      </div>
    </section>
  )
}

export default Metrics
