import React from "react"
import ArrowYDown from "../../common/ArrowYDown"

const Kv = ({ headTtl, blockTtl, blockTxt }) => {
  return (
    <section className="p-common-kv p-openness-kv under">
      <div className="p-common-kv__wrapper">
        <div className="p-common-kv__head">
          <h1 className="p-common-kv__head-ttl">{headTtl}</h1>
        </div>
        <div className="p-common-kv__block">
          <div className="p-common-kv__content">
            <h2 className="c-ttl__main p-common-kv__content-ttl">
              <div dangerouslySetInnerHTML={{ __html: blockTtl }} />
            </h2>
            <p
              className="c-txt__main p-common-kv__content-txt"
              dangerouslySetInnerHTML={{ __html: blockTxt }}
            />
          </div>
        </div>
        <button
          className="js-hover p-common-kv__arrow"
          data-updown-trigger="kv"
          data-hover-in-time="600"
          aria-label="スクロールダウンボタン"
        >
          <ArrowYDown />
        </button>
        <div className="p-common-kv__img" data-updown-target="kv"></div>
      </div>
    </section>
  )
}

export default Kv
